<template>
    <template v-if="isBeta">
        <div
            class="pointer-events-none absolute top-0 left-0 z-50 flex h-5 w-full bg-red-500/20 text-center text-sm font-medium text-red-500"
        >
            <div class="relative w-full">
                <div class="absolute right-0 left-0">
                    <strong>Beta preview</strong>
                    - Some features may not work as expected
                </div>

                <div class="absolute right-0 mr-2 flex h-full items-center">
                    <GFButtonIcon
                        icon="times-circle"
                        class="pointer-events-auto self-center text-red-500 hover:text-red-600"
                        @click="clearBeta"
                    />
                </div>
            </div>
        </div>
    </template>
</template>
<script setup lang="ts">
import { useBeta } from "@/composables/useBeta"
import GFButtonIcon from "../base/GFButtonIcon.vue"

const { isBeta, clearBeta } = useBeta()
</script>

import axios from "axios"
import config from "@/config"
import useNotification from "./useNotification"

const versionKey = "version"

const triggered = ref(false)
const intervalId = ref<null | NodeJS.Timer>(null)

/**
 * Checks the version of the app and triggers a refresh if the version has changed.
 */
export async function useVersionRefresh() {
    const useVersionCacheBusting =
        import.meta.env.VITE_VERSION_CACHE_BUSTING === "true"

    // turn off version cache busting by setting VITE_VERSION_CACHE_BUSTING to false
    if (useVersionCacheBusting === false) return

    const version = await fetchVersion()

    storeInSession(version)

    intervalId.value = setInterval(async () => {
        if (triggered.value) return

        const version = await fetchVersion()

        // If we can't fetch the version, we can't do anything.
        if (!version) return

        if (!matchesSession(version)) {
            triggered.value = true

            useNotification().open({
                message:
                    "New version of Galaxy Forms is available. Please refresh.",
                title: "Refresh required",
                type: "info",
                duration: false,
                draggable: false,
                hideCloseButton: true,
                actions: [
                    {
                        text: "Refresh",
                        click: () => {
                            window.location.reload()
                        },
                        type: "primary",
                    },
                ],
            })
        }
    }, config.versionRefreshInterval)
}

/**
 * Fetches the version from the server.
 */
async function fetchVersion() {
    const url = import.meta.env.VITE_VERSION_URL + "?v=" + Date.now()
    if (!url) return

    try {
        const response = await axios.get(url)
        return response.data
    } catch (e) {
        intervalId.value && clearInterval(intervalId.value)
        console.error("Failed to fetch version from server.", e)
    }
}

function storeInSession(version: string) {
    sessionStorage.setItem(versionKey, version)
}

function getFromSession() {
    const sessionVersion = sessionStorage.getItem(versionKey)
    return sessionVersion
}

function matchesSession(version: string | number) {
    const sessionVersion = getFromSession()
    return (
        sessionVersion ===
        (typeof version === "string" ? version : version.toString())
    )
}

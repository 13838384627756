<template>
    <BetaBanner />

    <router-view name="SigneeLoginView" />

    <router-view name="PostSigningLandingView" />

    <router-view name="PresentationView" />

    <!-- All non-named routes at the root level, mainly TheWrapper -->
    <router-view />

    <MaintenanceModeModal
        :show="showMaintenanceModal"
        @success="showMaintenanceModal = false"
    />
</template>
<script setup lang="ts">
import { useIdle } from "@vueuse/core"
import { useAppStore } from "./stores/app"
import useAuth from "./composables/useAuth"
import { useUserStore } from "./stores/user"
import { useColorsStore } from "@/stores/colors"
import BetaBanner from "./components/beta/BetaBanner.vue"
import { useVersionRefresh } from "@/composables/useVersionRefresh"
import { onAuthUserRoleChange } from "./middleware/onAuthUserRoleChange"
import MaintenanceModeModal from "./components/modals/MaintenanceModeModal.vue"

const router = useRouter()

const { showMaintenanceModal } = storeToRefs(useAppStore())

/**
 * Colors must be initialized on app start
 */
useColorsStore().setCSSVars()

/**
 * If active after 5 min of inactivity, refresh the user data.
 */
const { idle } = useIdle(5 * 60 * 1000) // 5 min
watch(idle, (isIdle) => {
    if (!isIdle) {
        useAuth().getMe()
    }
})

/************************************
 * Middleware
 *
 * If possible, register middleware here.
 ************************************/

/**
 * Middleware for when the user role changes
 */
watch(
    () => useUserStore().userOrgRole,
    (current, previous) => {
        // Middleware should not run if current is undefined (that means the app is still booting)
        if (current) onAuthUserRoleChange(current, previous, { router })
    }
)

useVersionRefresh()
</script>

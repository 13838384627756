<template>
    <GFModalSimple hide-buttons size="large" :show="show" :locked="true">
        <div
            class="mx-auto flex h-12 w-12 flex-shrink-0 items-center justify-center rounded-full sm:mx-0 sm:h-10 sm:w-10"
        >
            <GFSpinner></GFSpinner>
        </div>
        <div class="mt-3 text-center sm:mt-0 sm:ml-4 sm:text-left">
            <DialogTitle
                as="h3"
                class="text-lg font-medium leading-6 text-gray-900"
            >
                Server down
            </DialogTitle>
            <div class="mt-2 flex flex-col space-y-3">
                <div>
                    Give us a few minutes, we're updating Galaxy Forms. We'll be
                    right back!
                </div>
                <ServerDownSVG></ServerDownSVG>
            </div>
        </div>
    </GFModalSimple>
</template>
<script setup lang="ts">
import useAxios from "@/composables/useAxios"
import { DialogTitle } from "@headlessui/vue"
import GFSpinner from "../base/GFSpinner.vue"
import ServerDownSVG from "../svg/ServerDownSVG.vue"
import GFModalSimple from "../base/GFModalSimple.vue"

const emit = defineEmits<{
    (e: "success"): void
}>()
const props = defineProps<{ show: boolean }>()
const { show } = toRefs(props)

const intervalID = ref()
const retryInterval = 5000

watch(show, (shouldPing) => {
    if (shouldPing) intervalID.value = setInterval(pingAPI, retryInterval)
    else clearInterval(intervalID.value)
})

async function pingAPI() {
    try {
        await useAxios().axios.get("/api/meta/status")
        emit("success")
    } catch (e) {
        console.error("GF: Server down", e)
    }
}
</script>
